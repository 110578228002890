<template>
  <div>
    <div
      v-if="status == 'pending'"
      class="vh-100 d-flex flex-column justify-content-between p-5"
    >
      <div v-if="isPublic">
        <h5 class="text-left">Dear {{ this.visitor.name }},</h5>

        <h5 class="text-left pt-4">
          You have been invited for a visit by {{ host.name }} ,
          {{ company.name }}.
        </h5>
      </div>

      <div class="d-flex flex-row justify-content-between">
        <div class="d-flex flex-column w-100">
          <!-- visit details kiosk -->
          <div
            class="py-4"
            v-if="
              isKiosk &&
              Object.keys(visit).length !== 0 &&
              Object.keys(invite).length !== 0
            "
          >
            <h5><strong>Visit Code:</strong> #{{ visit.visit_code }}</h5>
            <h5><strong>Host:</strong> {{ host.name }} , {{ company.name }}</h5>
            <h5>
              <strong>Date / Time:</strong>
              {{ invite.start_datetime | date }}
              {{ visit.visit_start_time | unixToTime12Hours }} -
              {{ invite.end_datetime | date }}
              {{ visit.visit_end_time | unixToTime12Hours }}
            </h5>
            <h5>
              <strong>Visitor Type:</strong>
              {{ visit.visitor_type.toLowerCase() | humanizeText }}
            </h5>
            <h5>
              <strong>Remark:</strong> {{ visit.remark | dashForEmptyText }}
            </h5>
          </div>

          <!-- visit details public -->
          <div
            class="py-4"
            v-if="
              isPublic &&
              Object.keys(visit).length !== 0 &&
              Object.keys(invite).length !== 0
            "
          >
            <h5><strong>Visit Code:</strong> #{{ visit.visit_code }}</h5>
            <h5>
              <strong>Date / Time:</strong>
              {{ invite.start_datetime | date }}
              {{ visit.visit_start_time | unixToTime12Hours }} -
              {{ invite.end_datetime | date }}
              {{ visit.visit_end_time | unixToTime12Hours }}
            </h5>
            <h5>
              <strong>Visitor Type:</strong>
              {{ visit.visitor_type | dashForEmptyText | humanizeText }}
            </h5>
            <h5>
              <strong>Remark:</strong> {{ visit.remark | dashForEmptyText }}
            </h5>
          </div>

          <!-- visitor details kiosk-->
          <div class="py-4" v-if="isKiosk && Object.keys(visitor).length !== 0">
            <h5><strong>Your Details</strong></h5>
            <br />
            <h5 v-if="visitor?.face_image != null">
              <strong>Profile picture:</strong>
              <div class="visitor-card-face-image">
                <img :src="showImage(visitor?.face_image)" fluid />
              </div>
            </h5>
            <h5><strong>Name:</strong> {{ visitor?.name }}</h5>
            <h5><strong>Email:</strong> {{ visitor?.email }}</h5>
            <h5
              v-if="visitor?.nric_number != null && visitor?.nric_number != ''"
            >
              <strong>NRID / Passport number:</strong>
              {{ visitor?.nric_number | dashForEmptyText }}
            </h5>
            <h5
              v-if="
                visitor?.passport_number != null &&
                visitor?.passport_number != ''
              "
            >
              <strong>NRID / Passport number:</strong>
              {{ visitor?.passport_number | dashForEmptyText }}
            </h5>
            <h5>
              <strong>Contact number:</strong>
              {{ visitor?.contact_number | dashForEmptyText }}
            </h5>
            <h5>
              <strong>Company:</strong>
              {{ visitor?.profile?.company | dashForEmptyText }}
            </h5>
            <h5>
              <strong>Car plate number:</strong>
              {{ visitor?.profile?.car_plate_no | dashForEmptyText }}
            </h5>
          </div>
        </div>
      </div>

      <!-- declaration form -->
      <b-card
        class="my-3 bg-surface-2"
        no-body
        v-if="
          isKiosk &&
          getPreference.additional_metadata.vms_registration_declaration_form
        "
      >
        <b-card-header header-tag="header" role="tab">
          <h6><strong> Declaration Form </strong></h6>
        </b-card-header>
        <b-card-body>
          <b-form id="declaration_form" class="form-preview"></b-form>
        </b-card-body>
      </b-card>

      <!-- visitor details -->
      <b-card class="my-3 bg-surface-2" no-body v-if="isPublic">
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle.advance class="d-flex justify-content-between">
            <div class="h6">
              <strong>Your Details</strong>
            </div>
          </div>
        </b-card-header>
        <b-card-body>
          <b-form @submit="acceptInvitation">
            <b-form-group
              v-if="this.uploadImageRequired()"
              id="input-group-profile-picture"
              label="Profile Picture:"
              label-for="input-profile-picture"
            >
              <b-button
                class="mr-1"
                size="md"
                variant="warning"
                v-b-tooltip.hover
                title="Take Photo"
                @click="showAddVisitorImageModal"
                :disabled="allow"
              >
                <i class="fas fa-camera"></i>
              </b-button>
            </b-form-group>
            <div
              class="row justify-content-center mt-2"
              v-if="visitor.face_image != null"
            >
              <div class="visitor-card-face-image" style="cursor: pointer">
                <img :src="showImage(visitor.face_image)" fluid />
              </div>
            </div>

            <b-form-group
              id="input-group-name"
              label="Full name:"
              label-for="input-name"
            >
              <b-form-input
                v-model="$v.visitor.name.$model"
                placeholder="Enter name"
                :disabled="allow"
                :state="validateFormUpdate('name')"
                aria-describedby="formUpdate.name_feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="formUpdate.name_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              id="input-group-gender"
              label="Gender"
              label-for="input-gender"
            >
              <b-form-select
                id="input-gender"
                v-model="selectedVisitorGender"
                :options="visitorGenderOptions"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              id="input-group-email"
              label="Visitor email:"
              label-for="input-email"
            >
              <b-form-input
                v-model="visitor.email"
                placeholder="Enter email"
                disabled
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-company"
              label="Company:"
              label-for="input-company"
            >
              <b-form-input
                v-model="$v.companyVisitor.$model"
                placeholder="Enter company"
                :disabled="allow"
                :state="validateCompanyVisitor('companyVisitor')"
                aria-describedby="formUpdate.companyVisitor_feedback"
              ></b-form-input>
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="formUpdate.companyVisitor_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>
            <b-form-group
              v-if="
                public_preference?.additional_metadata
                  ?.vms_credential_operator_license_plate_recognition
              "
              id="input-group-car_plate"
              label="Car Plate No :"
              label-for="input-car_plate"
            >
              <b-form-input
                id="input-car_plate_no"
                v-model="$v.carPlateNo.$model"
                placeholder="Enter Car Plate No: ABC123"
                :state="validateCarPlateNo()"
                aria-describedby="formUpdate.car_plate_no_feedback"
              ></b-form-input
              >NOTE : Key in "-" if no license plate number.
              <b-form-invalid-feedback
                class="invalid-feedback"
                id="formUpdate.car_plate_no_feedback"
              >
                This field is required.
              </b-form-invalid-feedback>
            </b-form-group>

            <b-form-group
              v-if="
                !public_preference?.additional_metadata
                  ?.vms_credential_operator_license_plate_recognition
              "
              id="input-group-car_plate"
              label="Car Plate No :"
              label-for="input-car_plate"
            >
              <b-form-input
                v-model="carPlateNo"
                placeholder="Enter Car Plate No: ABC123"
              ></b-form-input
              >NOTE : Key in "-" if no license plate number.
            </b-form-group>

            <b-form-group
              id="input-group-contact_number"
              label="Contact Number:"
              label-for="input-contact_number"
            >
              <VuePhoneNumberInput
                v-bind="vuePhone.props"
                v-model="$v.contact_number.$model"
                :state="validateContactNumber()"
                @input="handleRegexPhoneNumber"
                aria-describedby="formUpdate_contact_number_feedback"
              />
              <div
                v-if="result == false"
                class="invalid-contact-number-feedback"
                id="formUpdate_contact_number_feedback"
              >
                This field is required.
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-national-id"
              label="National ID Type"
              label-for="input-national-id"
            >
              <b-row no-gutters>
                <b-col sm="5">
                  <b-form-select
                    id="select-national-id"
                    v-model="selectedIDType"
                    :options="nationalIDOptions"
                    required
                    @change="handleChangeID"
                    :disabled="allow"
                  ></b-form-select>
                </b-col>
                <b-col sm="7">
                  <!-- handlePlaceholder(selectedIDType) -->
                  <b-form-input
                    id="input-national-id"
                    v-model="selectedIDName"
                    @input="handleInput(selectedIDType, selectedIDName)"
                    :state="validateFormUpdate(selectedIDType)"
                    :aria-describedby="selectedIDType"
                    placeholder="Please input......."
                    :disabled="allow"
                    class="mb-1"
                  ></b-form-input>

                  <b-form-invalid-feedback
                    v-if="
                      selectedIDType === 'nric_number' &&
                      !$v.visitor.nric_number.required
                    "
                    class="invalid-feedback"
                    :id="selectedIDType"
                  >
                    This field is required.
                  </b-form-invalid-feedback>
                  <b-form-invalid-feedback
                    v-if="
                      selectedIDType === 'passport_number' &&
                      !$v.visitor.passport_number.required
                    "
                    class="invalid-feedback"
                    :id="selectedIDType"
                  >
                    This field is required.
                  </b-form-invalid-feedback>
                </b-col>
              </b-row>
            </b-form-group>
          </b-form>
        </b-card-body>
      </b-card>

      <!-- send button -->
      <div>
        <b-row no-gutters>
          <b-button
            v-if="isKiosk"
            class="w-100"
            variant="primary"
            @click="submitAndCreateVisitAccess()"
            >Continue
          </b-button>
          <b-col lg="6">
            <b-button
              v-if="isPublic"
              class="w-100 mb-2"
              variant="secondary"
              @click="declineInvitation(invite.invite_code)"
              >Decline
            </b-button>
          </b-col>
          <b-col lg="6">
            <b-button
              v-if="isPublic"
              class="w-100"
              variant="primary"
              @click="acceptInvitation(invite.invite_code)"
              >Submit
            </b-button>
          </b-col>
        </b-row>
      </div>
    </div>

    <div
      v-else-if="status === 'accepted_manual'"
      class="vh-100 d-flex flex-column justify-content-center align-items-center p-5"
    >
      <h5 class="text-center">
        <strong> Thank you for accept the invitation. </strong>
      </h5>
    </div>

    <div
      v-else-if="status === 'accepted_contactless'"
      class="vh-100 d-flex flex-column justify-content-center align-items-center p-5"
    >
      <h5 class="text-center">
        <strong>
          Thank you for accept the invitation. Your visit confirmation is
          received.
        </strong>
        <br />
        <br />
        <b>Visitation Code</b> : <span>{{ visit.visit_code }}</span>
        <br />
        <span
          >You will receive your visit details and access credential via
          email.</span
        >
        <div>
          However, if you cannot find your email within the inbox, kindly check
          your "Junk Mail" or "Spam" folder.
        </div>
        Thank you.
      </h5>
    </div>

    <div
      v-else
      class="vh-100 d-flex flex-column justify-content-center align-items-center p-5"
    >
      <h5 class="text-center">
        <strong> Sorry for letting you go and see you next time! </strong>
      </h5>
    </div>

    <b-modal
      id="form-add-visitor-image"
      ref="formAddVisitorImage"
      title="Profile Picture"
      centered
      hide-footer
      :no-close-on-backdrop="true"
      :hide-header-close="false"
      @close="doneTakePhoto"
    >
      <div class="row justify-content-center mt-2" v-if="capturedVisitorImage">
        <div>
          <img :src="showImage(visitor.face_image)" fluid />
        </div>
      </div>
      <div
        class="row justify-content-center mt-2"
        v-show="capturedVisitorImage == false"
      >
        <div id="live_video_container" ref="cameraView"></div>
      </div>

      <div class="row justify-content-center mt-2">
        *Note : Please uncover and position your face in front of the camera.
      </div>

      <div class="d-flex justify-content-center">
        <!-- <b-button class="my-2 mx-1">Upload File</b-button> -->
        <b-button
          class="my-2 mx-1"
          @click="retakePhoto"
          v-if="capturedVisitorImage"
          >Retake Photo
        </b-button>
        <b-button
          class="my-2 mx-1"
          variant="primary"
          @click="takePhoto"
          v-if="!capturedVisitorImage"
          >Take Photo
        </b-button>
        <b-button
          class="my-2 mx-1"
          size="md"
          variant="warning"
          v-b-tooltip.hover
          @click="getWebCamDeviceList"
          :disabled="allow"
          v-if="!capturedVisitorImage"
        >
          <i class="fa-solid fa-camera-rotate"></i>
        </b-button>
      </div>

      <div class="mt-4 d-flex justify-content-end">
        <b-button v-if="isPublic" variant="primary" @click="doneTakePhoto"
          >OK</b-button
        >
        <b-button v-else variant="primary" @click="onSubmitUploadPictureForm"
          >Submit</b-button
        >
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import Compressor from "compressorjs";
import * as imageConversion from "image-conversion";
let convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
import jQuery from "jquery";
let $ = (jQuery = require("jquery"));
$ = $.extend(require("webpack-jquery-ui"));
$ = $.extend(require("webpack-jquery-ui/css"));
$ = $.extend(require("formBuilder"));
$ = $.extend(require("formBuilder/dist/form-render.min.js"));
// const regex_nric = helpers.regex(regex_nric, /^\d{6}-\d{2}-\d{4}$/);
export default {
  mixins: [validationMixin],
  components: {
    VuePhoneNumberInput
  },
  data: function () {
    return {
      allow: false,
      isKiosk: false,
      isPublic: false,
      isDisabled: false,

      countTakePhoto: 0,
      capturedVisitorImage: false,

      carPlateNo: null,
      capturedPhoto: null,
      contact_number: null,
      companyVisitor: null,

      status: "pending",
      selectedIDType: "passport_number",

      selectedIDName: null,
      selectedVisitorObj: null,
      selectedVisitorGender: null,

      nationalIDOptions: [],
      visitorGenderOptions: [
        { value: "male", text: "Male" },
        { value: "female", text: "Female" }
      ],
      host: {},
      visit: {},
      invite: {},
      visitor: {},
      company: {},
      public_preference: {},

      acsUrls: [],
      all_access_groups: {},
      all_access_levels: {},

      form: {
        access_types: [],
        access_groups: [],
        access_level: null,
        access_card_no: null,
        selected_acs_url: null
      },
      formRender: [],

      result: null,
      cameraType: null,
      qr_code_type: null,
      scanErrorMessage: null,

      vuePhone: {
        phone: "",
        props: {
          clearable: true,
          defaultCountryCode: "MY",
          preferredCountries: ["SG", "ID"],
          noExample: true,
          translations: {
            countrySelectorLabel: "",
            countrySelectorError: "Error",
            example: "Example:",
            phoneNumberLabel: "Example: 188888888"
          }
        }
      }
    };
  },
  watch: {
    getPreference: {
      deep: true,
      handler: function (newVal) {
        if (newVal.forms.formData) {
          this.setFormData(newVal.forms.formData);
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getCurrentUser: "session/getCurrentUser",
      getAPIServerURL: "session/getAPIServerURL",
      getCountryCode: "session/getCountryCode"
    }),
    getPreference() {
      return this.$store.getters["vmsPreferences2Module/getPreferences"];
    }
  },
  validations: {
    visitor: {
      name: { required },
      face_image: { required },
      nric_number: { required },
      contact_number: { required },
      passport_number: { required }
    },
    carPlateNo: { required },
    contact_number: { required },
    companyVisitor: { required }
  },
  mounted: function () {
    if (this.$route.params.parent_url != undefined) {
      this.isKiosk = true;
      this.$store.dispatch("vmsPreferences2Module/getAllPreferences");
    } else {
      this.isPublic = true;
      this.initPublic();
    }
    this.getVisitInvite(this.$route.params.invite_code);
  },
  methods: {
    initPublic() {
      let API_URL = this.getAPIServerURL + "/public/vms/visit-preference/";
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL)
        .then((data) => {
          this.public_preference = data;
          this.getNationalID();
          if (this.public_preference?.forms?.formData) {
            this.setFormData(this.public_preference.forms.formData);
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },

    getNationalID() {
      //this.getCountryCode = "IDN";
      let country_code = null;
      for (let key in this.public_preference.additional_metadata
        .national_id_type) {
        if (this.getCountryCode == key) {
          country_code = key;
        }
      }

      for (let key2 in this.public_preference.additional_metadata
        .national_id_type[country_code]) {
        if (
          this.public_preference.additional_metadata.national_id_type[
            country_code
          ][key2] == "Passport"
        ) {
          this.nationalIDOptions.push({
            value: "passport_number",
            text: this.public_preference.additional_metadata.national_id_type[
              country_code
            ][key2]
          });
        } else {
          this.nationalIDOptions.push({
            value: "nric_number",
            text: this.public_preference.additional_metadata.national_id_type[
              country_code
            ][key2]
          });
        }
      }
    },

    getVisitInvite(invite_code) {
      let API_URL = null;
      if (this.isKiosk) {
        API_URL = this.getAPIServerURL + "/api/visitorservice/invite/";
      } else {
        API_URL = this.getAPIServerURL + "/public/vms/invite/";
      }
      const client = this.$root.getAjaxFetchClient();
      client
        .getRequest(API_URL, { invite_code: invite_code })
        .then(async (data) => {
          let validUrl = "";
          let result = data.result;
          if (result != undefined) {
            outer_loop: for (let key in result) {
              if (result[key] != undefined) {
                validUrl = key;
                break outer_loop;
              }
            }
          }
          if (result[validUrl] != undefined) {
            this.invite = result[validUrl];
            this.visit = await this.getVisitInfo(this.invite.visit_id);
            this.visitor = await this.getVisitor(this.invite.visit_id);
            this.host = await this.getHost(this.invite.visit_id);
            this.company = await this.getCompany(this.host.company_id);
            console.log("this.invite", this.invite);
            console.log("visit", this.visit);
            console.log("visitor", this.visitor);
            console.log("host", this.host);
            console.log("company", this.company);
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    },
    async getVisitInfo(visit_id) {
      let API_URL = null;
      if (this.isKiosk) {
        API_URL = this.getAPIServerURL + "/api/visitorservice/visit/";
      } else {
        API_URL = this.getAPIServerURL + "/public/vms/visit/";
      }
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL, { visit_id: visit_id });
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (result[validUrl] != undefined) {
        return result[validUrl];
      }
      return {};
    },
    async getVisitor(visit_id) {
      let API_URL = null;
      let vms_pdpa_setting = null;
      if (this.isKiosk) {
        vms_pdpa_setting =
          this.getPreference.additional_metadata.vms_pdpa_setting;
        API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/visit/" +
          visit_id +
          "/visitors/";
      } else {
        vms_pdpa_setting =
          this.public_preference.additional_metadata.vms_pdpa_setting;
        API_URL =
          this.getAPIServerURL + "/public/vms/visit/" + visit_id + "/visitors/";
      }
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (result[validUrl] != undefined) {
        if (result[validUrl] != undefined) {
          for (let key2 in result[validUrl]) {
            if (vms_pdpa_setting == "save_partial") {
              if (result[validUrl][key2].passport_number != null) {
                result[validUrl][key2].passport_number = this.getPartial(
                  result[validUrl][key2].passport_number
                );
              }

              if (result[validUrl][key2].nric_number != null) {
                result[validUrl][key2].nric_number = this.getPartial(
                  result[validUrl][key2].nric_number
                );
              }

              if (
                result[validUrl][key2].passport_number != null ||
                result[validUrl][key2].nric_number != null
              ) {
                result[validUrl][key2].custom_id = this.getCustomId(
                  result[validUrl][key2]
                );
              } else {
                result[validUrl][key2].custom_id = null;
              }
            }
            this.companyVisitor = result[validUrl][key2].profile.company;
            let contact_number = null;
            if (result[validUrl][key2].contact_number != null) {
              contact_number =
                result[validUrl][key2].contact_number.substring(3);
            }
            this.contact_number = contact_number;
            return result[validUrl][key2];
          }
        }
      }
      return {};
    },
    async getHost(visit_id) {
      let API_URL = null;
      if (this.isKiosk) {
        API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/visit/" +
          visit_id +
          "/hosts/";
      } else {
        API_URL =
          this.getAPIServerURL + "/public/vms/visit/" + visit_id + "/hosts/";
      }
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (result[validUrl] != undefined) {
        return result[validUrl][0];
      }
      return {};
    },
    async getCompany(company_id) {
      let API_URL = null;
      if (this.isKiosk) {
        API_URL =
          this.getAPIServerURL +
          "/api/visitorservice/company/?company_id=" +
          company_id;
      } else {
        API_URL =
          this.getAPIServerURL +
          "/public/vms/company/?company_id=" +
          company_id;
      }
      const client = this.$root.getAjaxFetchClient();
      let res = await client.getRequest(API_URL);
      let result = res.result;
      let validUrl = "";
      if (result !== undefined) {
        outer_loop: for (let key in result) {
          if (result[key] !== undefined) {
            validUrl = key;
            break outer_loop;
          }
        }
      }
      if (result[validUrl] != undefined) {
        return result[validUrl];
      }
      return {};
    },

    // settin upload image
    uploadImageRequired() {
      let result = null;
      if (this.isPublic) {
        result =
          (this.public_preference?.additional_metadata
            ?.vms_registration_visitor_profile_picture_required ||
          this.public_preference?.additional_metadata
            ?.vms_credential_operator_face_recognition
            ? true
            : false) ?? false;
      } else {
        result =
          (this.getPreference?.additional_metadata
            ?.vms_registration_visitor_profile_picture_required ||
          this.getPreference?.additional_metadata
            ?.vms_credential_kiosk_face_recognition
            ? true
            : false) ?? false;
      }

      return result;
    },

    onSubmitUploadPictureForm() {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visitor/?visitor_id=" +
        $this.visitor.id;
      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, $this.visitor)
        .then((data) => {
          // update visitor to store
          let updateVisitor;
          outer_loop: for (let key in data.result) {
            if (data.result[key] == null) {
              updateVisitor = data.result[key];
              if (updateVisitor == null) {
                this.stopCamera();
                this.removeCameraStream();
                this.$bvModal.hide("form-add-visitor-image");
                $this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: "Updated visitor successfully",
                  message_type: "success"
                });
              } else {
                if (data.detail != undefined) {
                  $this.$store.dispatch("session/addGlobalAlertMessage", {
                    message_text: data.detail,
                    message_type: "danger"
                  });
                }
              }
              break outer_loop;
            }
          }
        })
        .catch((err) => {
          $this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
          // if (err.status == 401) {
          //   $this.$store.dispatch("session/logoutSession");
          // }
        });
    },

    showImage: function (img) {
      let $this = this;
      return img ? `data:image/jpeg;base64,${img}` : $this.defaultImage;
    },
    showAddVisitorImageModal() {
      this.getWebCamDeviceList();
      this.$bvModal.show("form-add-visitor-image");
    },
    /// web cam
    getWebCamDeviceList() {
      this.handleCamera();
      let live_video;

      if (this.countTakePhoto == 0) {
        live_video = document.createElement("video");
        live_video.setAttribute("id", "camera_stream");
        live_video.setAttribute("width", 350);
        live_video.setAttribute("height", 350);
        live_video.setAttribute("autoplay", true);
        live_video.setAttribute("muted", "");
        live_video.setAttribute("playsinline", "");

        const constraints = (window.constraints = {
          audio: false,
          video: {
            width: 350,
            height: 350,
            facingMode: this.cameraType
          }
        });

        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            live_video.srcObject = stream;
            this.$refs.cameraView.appendChild(live_video);

            this.countTakePhoto++;
          })
          .catch((error) => {
            console.log(error);
            // error = DOMException: Permission denied
            alert(
              "Your connection is not secured. Please contact your system administrator."
            );
            this.$bvModal.hide("form-add-visitor-image");
            this.countTakePhoto = 0;
          });
      }
    },
    async takePhoto() {
      let $this = this;
      let canvas = document.createElement("canvas");
      let video = document.getElementById("camera_stream");

      canvas.width = video.videoWidth * 0.8;
      canvas.height = video.videoHeight * 0.8;
      canvas
        .getContext("2d")
        .drawImage(video, 0, 0, canvas.width, canvas.height);

      let base64Image = canvas.toDataURL("image/jpeg");

      let base64Image2 = base64Image;
      // let base64Image2 = await this.scCompressBase64Image(base64Image);
      let base64str = base64Image2.substring(base64Image2.indexOf(",") + 1);

      $this.capturedPhoto = base64str;
      $this.visitor.face_image = $this.capturedPhoto;

      if ($this.selectedVisitorObj != null) {
        $this.selectedVisitorObj.face_image = $this.capturedPhoto;
      }

      this.capturedVisitorImage = true;
    },
    retakePhoto: function () {
      this.capturedVisitorImage = false;
    },
    stopCamera: function () {
      let $this = this;
      let tracks = null;
      if ($this.$refs?.cameraView) {
        if ($this.$refs?.cameraView?.lastChild) {
          tracks = $this.$refs?.cameraView?.lastChild?.srcObject.getTracks();
          tracks.forEach((track) => {
            track.stop();
          });
        }
      }

      this.countTakePhoto = 0;
    },
    removeCameraStream: function () {
      let $this = this;
      if ($this.$refs.cameraView != null) {
        while ($this.$refs.cameraView.hasChildNodes()) {
          $this.$refs.cameraView.removeChild($this.$refs.cameraView.lastChild);
        }
      }
      this.countTakePhoto = 0;
    },
    doneTakePhoto: function () {
      this.countTakePhoto = 0;
      this.stopCamera();
      this.removeCameraStream();
      this.$bvModal.hide("form-add-visitor-image");
    },

    //get partial
    getPartial(input) {
      let length = input.length;
      let input2 = input.substring(0, length - 4).split("");
      let input3 = input.substring(length - 4);

      let output = [];
      input2.forEach((letter) => {
        letter = "*";
        output.push(letter);
      });

      let answer = output.join("") + input3;
      return answer;
    },
    // get custom id
    getCustomId(data) {
      let contact_number = data.contact_number;
      let length1 = contact_number.length;
      contact_number = contact_number.substring(length1 - 4);
      //let email = data.email.substring(4, 0);
      let custom_id = null;
      if (data.passport_number != null) {
        let length2 = data.passport_number.length;
        let passport_number = data.passport_number.substring(length2 - 4);
        custom_id = contact_number + passport_number;
        return custom_id;
      } else {
        let length3 = data.nric_number.length;
        let nric_number = data.nric_number.substring(length3 - 4);
        custom_id = contact_number + nric_number;
        return custom_id;
      }
    },

    //setForm
    setFormData: function (form_data) {
      let $this = this;
      let layoutType = "normal";
      let obj = JSON.parse(form_data);
      let obj2 = [];

      for (let key in obj) {
        if (
          obj[key].type === "text" ||
          obj[key].type === "textarea" ||
          obj[key].type === "number" ||
          obj[key].type === "autocomplete"
        ) {
          //console.log("obj[key]", obj[key]);
          obj[key]["data-layout"] = layoutType;
          if (obj[key].required === false) {
            obj[key].required = true;
          }
        } else if (
          obj[key].type === "radio-group" ||
          obj[key].type === "checkbox-group" ||
          obj[key].type === "select" ||
          obj[key].type === "date"
        ) {
          if (obj[key].required == false) {
            obj[key].required = true;
          }
        }
        obj2.push(obj[key]);
      }
      console.log("obj2", obj2);
      $this.formRender.push(
        $("#declaration_form").formRender({
          dataType: "json",
          formData: obj2
        })
      );

      console.log("$this.formRender", $this.formRender[0].formData);
    },

    // form add access
    formAddAccess() {
      let $this = this;

      this.form.access_types =
        this.$store.getters["vmsPreferences2Module/kioskPresetCredential"];

      if (
        this.getPreference.additional_metadata.vms_credential_kiosk_dynamic_qr
      ) {
        this.qr_code_type = "dynamic";
      } else {
        this.qr_code_type = "static";
      }

      // form access group and access level
      if (
        Object.keys(this.company.access_metadata).length !== undefined &&
        Object.keys(this.company.access_metadata).length > 0
      ) {
        for (let index in this.company.access_metadata.access_groups) {
          $this.form.access_groups.push(
            this.company.access_metadata.access_groups[index].access_group
          );
        }
        $this.form.access_level =
          this.company.access_metadata.access_level.access_level;
      }

      if (
        Object.keys(this.company.access_metadata).length !== undefined &&
        Object.keys(this.company.access_metadata).length === 0 &&
        this.getPreference.additional_metadata.vms_preferences_access_groups
          .length !== undefined &&
        this.getPreference.additional_metadata.vms_preferences_access_groups
          .length > 0
      ) {
        for (let index in this.getPreference.additional_metadata
          .vms_preferences_access_groups) {
          $this.form.access_groups.push(
            this.getPreference.additional_metadata
              .vms_preferences_access_groups[index].access_group
          );
        }

        $this.form.access_level =
          this.getPreference.additional_metadata.vms_preferences_access_level.access_level;
      }

      if (
        Object.keys(this.company.access_metadata).length !== undefined &&
        Object.keys(this.company.access_metadata).length === 0 &&
        this.getPreference.additional_metadata.vms_preferences_access_groups
          .length !== undefined &&
        this.getPreference.additional_metadata.vms_preferences_access_groups
          .length === 0
      ) {
        this.$bvModal.msgBoxOk(
          "Please setup for the company group access in SmartVMS settings.",
          {
            centered: true
          }
        );
        return false;
      }
    },
    //handle
    handleChangeID() {
      this.selectedIDName = null;
    },
    handleInput(idType, value) {
      if (idType == "nric_number") {
        this.visitor.nric_number = value;
      } else {
        this.visitor.passport_number = value;
      }
    },
    handlePlaceholder(idType) {
      let type = null;
      if (idType == "nric_number") {
        type = "NRID (MyKad) : 880808108888";
        return type;
      } else {
        type = "Passport : ABCD12345";
        return type;
      }
    },
    handleRegexPhoneNumber() {
      if (this.contact_number != null) {
        this.contact_number = this.contact_number.replace(/-/g, "");
      }
      this.visitor.contact_number = this.contact_number;
    },
    handleCamera() {
      if (this.cameraType == "user") {
        this.cameraType = "environment";
        console.log("this.cameraType1", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == "environment") {
        this.cameraType = "user";
        console.log("this.cameraType2", this.cameraType);

        this.stopCamera();
        this.removeCameraStream();
      } else if (this.cameraType == null) {
        this.cameraType = "user";
        console.log("this.cameraType3", this.cameraType);
      }

      this.capturedVisitorImage = false;
    },

    // validation
    validateContactNumber() {
      let result;
      if (this.$v.contact_number != null) {
        const { $dirty, $error } = this.$v.contact_number;
        result = $dirty ? !$error : null;
        this.result = result;
        return result;
      }
    },
    validateCompanyVisitor() {
      let result;
      if (this.$v.companyVisitor != null) {
        const { $dirty, $error } = this.$v.companyVisitor;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateCarPlateNo() {
      let result;
      if (this.$v.carPlateNo != null) {
        const { $dirty, $error } = this.$v.carPlateNo;
        result = $dirty ? !$error : null;
        return result;
      }
    },
    validateFormUpdate(field) {
      let result;
      if (field != null) {
        const { $dirty, $error } = this.$v.visitor[field];
        result = $dirty ? !$error : null;
        return result;
      }
    },

    onSubmitValidationVisitorForm() {
      let $this = this;

      $this.$v.visitor.$touch();
      $this.$v.contact_number.$touch();
      $this.$v.companyVisitor.$touch();
      $this.$v.carPlateNo.$touch();

      let isCarPlateNoRequired =
        this.public_preference?.additional_metadata
          ?.vms_credential_operator_license_plate_recognition ??
        this.getPreference?.additional_metadata
          ?.vms_credential_operator_license_plate_recognition;

      if (this.isPublic) {
        if ($this.selectedIDType == "nric_number") {
          if (
            $this.$v.visitor.contact_number.$anyError ||
            $this.$v.visitor.nric_number.$anyError ||
            $this.$v.visitor.name.$anyError ||
            (this.uploadImageRequired() &&
              $this.$v.visitor.face_image.$anyError) ||
            $this.$v.contact_number.$anyError ||
            $this.$v.companyVisitor.$anyError ||
            ($this.$v.carPlateNo.$anyError && isCarPlateNoRequired)
          ) {
            return false;
          } else {
            return true;
          }
        } else {
          if (
            $this.$v.visitor.contact_number.$anyError ||
            $this.$v.visitor.passport_number.$anyError ||
            $this.$v.visitor.name.$anyError ||
            (this.uploadImageRequired() &&
              $this.$v.visitor.face_image.$anyError) ||
            $this.$v.contact_number.$anyError ||
            $this.$v.companyVisitor.$anyError ||
            ($this.$v.carPlateNo.$anyError && isCarPlateNoRequired)
          ) {
            return false;
          } else {
            return true;
          }
        }
      } else {
        if (
          this.uploadImageRequired() &&
          !$this.$v.visitor.passport_number.$anyError
        ) {
          return true;
        } else if (
          this.uploadImageRequired() &&
          !$this.$v.visitor.nric_number.$anyError
        ) {
          return true;
        } else if (!this.uploadImageRequired()) {
          return true;
        } else if ($this.$v.carPlateNo.$anyError && isCarPlateNoRequired) {
          return false;
        } else {
          return false;
        }
      }
    },
    onSubmitValidationDeclarationForm() {
      let $this = this;
      let count = 0;
      for (let key in $this.formRender) {
        $this.formElement = $this.formRender[key].userData;
      }
      console.log("$this.formElement", $this.formElement);
      for (let key2 in $this.formElement) {
        if (
          $this.formElement[key2].required != undefined &&
          $this.formElement[key2].required == true
        ) {
          $this.userData = [];
          $this.userData.push($this.formElement[key2].userData);
        }
      }
      console.log("$this.userData", $this.userData);
      for (let key3 in $this.userData) {
        if ($this.userData[key3] == "" || $this.userData[key3] == undefined) {
          count++;
        }
      }
      console.log("count", count);
      if (count > 0) {
        return false;
      } else {
        return true;
      }
    },
    // update visit
    updateVisit(data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/?visit_id=" +
        this.visit.id;

      const client = $this.$root.getAjaxFetchClient();
      client
        .putRequest(API_URL, data)
        .then((data) => {
          if (data.detail != undefined) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: "Visit has been updated unsuccessfully",
              message_type: "danger"
            });
          } else {
            if (
              !$this.getPreference.additional_metadata
                .vms_credential_kiosk_physical_card &&
              ($this.getPreference.additional_metadata
                .vms_credential_kiosk_face_recognition ||
                $this.getPreference.additional_metadata
                  .vms_credential_kiosk_static_qr ||
                $this.getPreference.additional_metadata
                  .vms_credential_kiosk_dynamic_qr)
            ) {
              if (
                $this.getPreference.additional_metadata
                  .vms_check_in_as_soon_as_credential_is_issued
              ) {
                this.checkIn($this.visit);
                /* setTimeout(function () {
                  console.log("Wait 1 second before proceed checkin");
                  //call function checkin
                  this.checkIn($this.visit);
                }, 1000); */
              }
            } else {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Visit has been updated successfully",
                message_type: "success"
              });

              this.$router
                .push({
                  name: "visit-detail",
                  params: {
                    id: this.visit.id
                  },
                  query: {
                    parents_url: this.$route.params.parent_url
                  }
                })
                .catch(() => {});
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // create visit access - step 1
    submitAndCreateVisitAccess: function () {
      let $this = this;
      let data = {};
      let declaration_form_data = {};
      let validDeclarationForm = null;
      let validForm = this.onSubmitValidationVisitorForm();
      console.log("validForm", validForm);
      if (
        this.getPreference.additional_metadata.vms_registration_declaration_form
      ) {
        validDeclarationForm = this.onSubmitValidationDeclarationForm();

        if (validForm && validDeclarationForm) {
          this.formAddAccess();

          for (let i = 0; i < this.formRender.length; i++) {
            declaration_form_data[i] = this.formRender[i].userData;
          }

          if ($this.uploadImageRequired()) {
            if (this.visitor.face_image === null) {
              console.log("empty face");
              this.showAddVisitorImageModal();
              return;
            }
          }

          data = {
            additional_metadata: {
              ...this.visit.additional_metadata,
              visitor: {
                [$this.visitor.id]: {
                  declaration_form_data: declaration_form_data
                }
              }
            }
          };
          console.log("data2", data);
          this.updateVisit(data);
        }

        if (!validForm) {
          if ($this.uploadImageRequired()) {
            $this.$bvModal.msgBoxOk("Form not completed!", {
              centered: true
            });
          }
          return false;
        }

        if (!validDeclarationForm) {
          $this.$bvModal.msgBoxOk("Please fill out a required field.", {
            centered: true
          });

          return false;
        }
      }

      if (
        !this.getPreference.additional_metadata
          .vms_registration_declaration_form
      ) {
        if (validForm) {
          this.formAddAccess();
          if ($this.uploadImageRequired()) {
            if (this.visitor.face_image === null) {
              console.log("empty face");
              this.showAddVisitorImageModal();
              return;
            }
          }

          data = {
            additional_metadata: {
              ...this.visit.additional_metadata,
              visitor: {
                [$this.visitor.id]: {
                  declaration_form_data: null
                }
              }
            }
          };
          console.log("data2", data);
          this.updateVisit(data);
        }

        if (!validForm) {
          if ($this.uploadImageRequired()) {
            $this.$bvModal.msgBoxOk("Form not completed!", {
              centered: true
            });
          }
          return false;
        }
      }
    },
    // create visit - step 2
    checkIn(visit_data) {
      let $this = this;
      let API_URL =
        $this.getAPIServerURL +
        "/api/visitorservice/visit/check-in/?visit_id=" +
        visit_data.id;

      const client = $this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL, null)
        .then(async (data) => {
          console.log("checkIn_success", data);
          this.onSubmitAddAccessForm(visit_data);
          /* setTimeout(function () {
              console.log("Wait 1 second before proceed add access");
              this.onSubmitAddAccessForm(visit_data);
            }, 2000); */
        })
        .catch((error) => {
          console.log("data_error", error);
        });
    },
    // create visit - step 3
    onSubmitAddAccessForm(visit_data) {
      let $this = this;

      let API_URL = $this.getAPIServerURL + "/api/visitorservice/visit/access/";
      const client = $this.$root.getAjaxFetchClient();

      let data = {
        visit_id: visit_data?.id,
        visitor_id: this.visitor?.id,
        access_card_no: null,
        access_types: this.form.access_types,
        access_level: this.form?.access_level,
        access_groups: this.form?.access_groups,
        as_format: "base64",
        lift_access_level: 0,
        qr_code_type: this.qr_code_type,
        face_image: this.visitor?.face_image,
        car_plate_no: this.visitor?.profile?.car_plate_no
      };

      if (!this.form.access_types.includes("card")) {
        client
          .postRequest(API_URL, data)
          .then(async (data) => {
            if (data.detail != undefined) {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: data.detail,
                message_type: "danger"
              });

              $this.formErrorMessage = data.detail;
            } else {
              $this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: "Visit access has been added successfully",
                message_type: "success"
              });

              let result = data.result;
              let validUrl = "";
              if (result !== undefined) {
                outer_loop: for (var key in result) {
                  if (result[key] !== undefined) {
                    validUrl = key;
                    break outer_loop;
                  }
                }
              }

              if (result[validUrl]) {
                console.log("add_access_success", result[validUrl]);
                console.log("go to visit details");
                this.$router
                  .push({
                    name: "visit-detail",
                    params: {
                      id: visit_data.id
                    },
                    query: {
                      parents_url: this.$route.params.parent_url
                    }
                  })
                  .catch(() => {});
              }
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      } else {
        this.$router
          .push({
            name: "visit-detail",
            params: {
              id: visit_data.id
            },
            query: {
              parents_url: this.$route.params.parent_url
            }
          })
          .catch(() => {});
      }
    },

    //accept
    acceptInvitation(invite_code) {
      let country_code = $(".country-selector__input").val();
      this.visitor.contact_number = country_code + this.contact_number;
      this.visitor.profile.gender = this.selectedVisitorGender;
      this.visitor.profile.company = this.companyVisitor;
      if (this.carPlateNo == null) {
        this.visitor.profile.car_plate_no == "-";
      }
      this.visitor.profile.car_plate_no = this.carPlateNo;

      if (
        this.public_preference.additional_metadata.vms_pdpa_setting ==
        "save_partial"
      ) {
        if (this.selectedIDType == "passport_number") {
          this.visitor.nric_number = null;
          this.visitor.passport_number = this.getPartial(
            this.visitor.passport_number
          );
        } else {
          this.visitor.passport_number = null;
          this.visitor.nric_number = this.getPartial(this.visitor.nric_number);
        }

        this.visitor.custom_id = this.getCustomId(this.visitor);
      } else {
        if (this.selectedIDType == "passport_number") {
          this.visitor.nric_number = null;
        } else {
          this.visitor.passport_number = null;
        }
      }

      console.log("this.visitor", this.visitor);

      let validForm = this.onSubmitValidationVisitorForm();
      console.log("validForm", validForm);
      if (validForm) {
        if (
          this.public_preference.additional_metadata
            .vms_host_invite_check_in_auto
        ) {
          let API_URL =
            this.getAPIServerURL +
            "/public/vms/visitor/?visitor_id=" +
            this.visitor.id;

          const client = this.$root.getAjaxFetchClient();
          client.putRequest(API_URL, this.visitor).then((data2) => {
            // update visitor to store
            let updateVisitor;
            outer_loop: for (let key in data2.result) {
              if (data2.result[key] == null) {
                updateVisitor = data2.result[key];
                if (updateVisitor == null) {
                  this.$store.dispatch("visitor/updateVisitor", {
                    id: this.visitor.id,
                    data: {
                      face_image: this.visitor.face_image,
                      name: this.visitor.name.toUpperCase(),
                      nric_number: this.visitor.nric_number,
                      passport_number: this.visitor.passport_number,
                      contact_number: this.visitor.contact_number,
                      profile: {
                        company: this.visitor.profile.company,
                        car_plate_no: this.visitor.profile.car_plate_no,
                        gender: this.selectedVisitorGender
                      }
                    }
                  });

                  let API_URL =
                    this.getAPIServerURL +
                    "/public/vms/invite/accept/?invite_code=" +
                    invite_code;
                  const client = this.$root.getAjaxFetchClient();
                  client
                    .postRequest(API_URL)
                    .then((data) => {
                      if (data.detail) {
                        this.$store.dispatch("session/addGlobalAlertMessage", {
                          message_text: data.detail,
                          message_type: "danger"
                        });
                      } else {
                        this.status = "accepted_contactless";
                      }
                    })
                    .catch((err) => {
                      this.$store.dispatch("session/addGlobalAlertMessage", {
                        message_text: err.detail,
                        message_type: "danger"
                      });
                    });
                } else {
                  if (data2.detail != undefined) {
                    this.formUpdateErrorMessage = data2.detail;
                  }
                }
                break outer_loop;
              }
            }
          });
        }

        if (
          !this.public_preference.additional_metadata
            .vms_host_invite_check_in_auto
        ) {
          let API_URL =
            this.getAPIServerURL +
            "/public/vms/invite/accept/?invite_code=" +
            invite_code;
          const client = this.$root.getAjaxFetchClient();
          client
            .postRequest(API_URL)
            .then((data) => {
              if (data.detail) {
                this.$store.dispatch("session/addGlobalAlertMessage", {
                  message_text: data.detail,
                  message_type: "danger"
                });
              } else {
                let API_URL =
                  this.getAPIServerURL +
                  "/public/vms/visitor/?visitor_id=" +
                  this.visitor.id;

                const client = this.$root.getAjaxFetchClient();
                client.putRequest(API_URL, this.visitor).then((data2) => {
                  // update visitor to store
                  let updateVisitor;
                  outer_loop: for (let key in data2.result) {
                    if (data2.result[key] == null) {
                      updateVisitor = data2.result[key];
                      if (updateVisitor == null) {
                        this.$store.dispatch("visitor/updateVisitor", {
                          id: this.visitor.id,
                          data: {
                            face_image: this.visitor.face_image,
                            name: this.visitor.name.toUpperCase(),
                            nric_number: this.visitor.nric_number,
                            passport_number: this.visitor.passport_number,
                            contact_number: this.visitor.contact_number,
                            profile: {
                              company: this.visitor.profile.company,
                              car_plate_no: this.visitor.profile.car_plate_no,
                              gender: this.selectedVisitorGender
                            }
                          }
                        });

                        this.formUpdateID = null;
                        this.visitor.face_image = null;
                        this.visitor.name = null;
                        this.visitor.nric_number = null;
                        this.visitor.passport_number = null;
                        this.visitor.contact_number = null;
                        this.visitor.profile.company = null;
                        this.visitor.profile.car_plate_no = null;
                        this.visitor.profile.gender = null;

                        this.stopCamera();

                        this.$nextTick(() => {
                          this.$v.$reset();
                        });

                        this.status = "accepted_manual";
                      } else {
                        if (data2.detail != undefined) {
                          this.formUpdateErrorMessage = data2.detail;
                        }
                      }
                      break outer_loop;
                    }
                  }
                });
              }
            })
            .catch((err) => {
              this.$store.dispatch("session/addGlobalAlertMessage", {
                message_text: err.detail,
                message_type: "danger"
              });
            });
        }
      } else {
        if (this.uploadImageRequired() && this.visitor.face_image == null) {
          this.$bvModal.msgBoxOk("Please take a picture for profile picture.", {
            centered: true
          });
          return false;
        } /* else {
          this.$bvModal.msgBoxOk("Form not completed!", {
            centered: true
          });
          return false;
        } */
      }
    },
    //decline
    declineInvitation(invite_code) {
      let API_URL =
        this.getAPIServerURL +
        "/public/vms/invite/decline/?invite_code=" +
        invite_code;
      const client = this.$root.getAjaxFetchClient();
      client
        .postRequest(API_URL)
        .then((data) => {
          console.log("data");
          console.log(data);

          if (data.detail) {
            this.$store.dispatch("session/addGlobalAlertMessage", {
              message_text: data.detail,
              message_type: "danger"
            });
          } else {
            this.status = "declined";

            // this.$store.dispatch("session/addGlobalAlertMessage", {
            //   message_text: "Sorry for letting you go and see you next time!",
            //   message_type: "success"
            // });
          }
        })
        .catch((err) => {
          this.$store.dispatch("session/addGlobalAlertMessage", {
            message_text: err.detail,
            message_type: "danger"
          });
        });
    }

    /* let index = this.form.access_types.indexOf("card");
      if (index > -1) {
        // only splice array when item is found
        this.form.access_types.splice(index, 1); // 2nd parameter means remove one item only
      }
      console.log("form.access_types", this.form.access_types); */
  }
};
</script>

<style lang="scss">
@import "@/pages/SmartVms/App.scss";
</style>
